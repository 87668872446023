.search-filter {
  &__wrapper {
    padding: 2rem 0;
  }
  // &__search {
  //   width: 100% !important;
  // }
  &__select {
    width: 100% !important;
  }
  &__reset {
    height: 39px !important;
    font-size: larger;
    background-color: #286fd1;
  }

  &__reset:hover {
    background-color: #286fd1 !important;
  }

  &__markReceived {
    height: 39px !important;
    font-size: larger;
    background-color: #286fd1;
  }

  &__markReceived {
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }

    &:disabled:hover {
      background-color: #cccccc !important;
    }

    &:not(:disabled):hover {
      background-color: #286fd1 !important;
    }
  }

  &__infoMessage {
    color: #a0a0a0; /* Light grey color */
    font-size: 14px;
    padding-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

// Handle hover state to hide select
.search-filter__search:hover ~ .search-filter__select {
  display: none;
}
