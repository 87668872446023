.modal-header {
	justify-content: space-between;
	display: flex;

	align-items: center;
	margin-bottom: 10px;
	> h4 {
	
		text-align: center;
		margin: 0;
	}

	.template-link {
		text-decoration: underline;
		color: #286fd1;
		margin-right: 20px;
	}
	.template-link,
	.close-icon {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.right-align-items {
			display: flex;
			align-items: center;
	}
}


// .modal-header {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     position: relative; /* Allow absolute positioning of title */
//     margin-bottom: 10px;

//     h4 {
//         position: absolute; /* Position title absolutely */
//         left: 50%; /* Center horizontally */
//         transform: translateX(-50%); /* Adjust for true center */
//         margin: 0; /* Remove default margin */
//     }

//     .template-link,
//     .close-icon {
//         display: flex;
//         align-items: center;
//         justify-content: center;
//     }

// }


.modalUploadContainer {
	margin-top: 20px;
}

.centerLabel {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
	position: relative;

	h3 {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 1px solid grey;
		color: grey;
		z-index: 1;
	}

	&::before,
	&::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid grey;
		position: absolute;
		top: 50%;
		width: 70px;
	}

	&::before {
		left: 18rem;
	}

	&::after {
		right: 18rem;
	}
}
.buttons {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 30px;
}
.centerButtons {
	display: flex;
	justify-content: center;
	margin-top: 30px;
	margin-bottom: 30px;
}
