/* index.css */
.warranty-check {
  padding: 20px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 10px auto;

  /* Shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust offset, blur, and color as needed */
}

.logo {
  width: 180px;
  height: auto;
  margin-bottom: 30px;
}

.title {
  font-size: 2.8rem; /* Larger font size */
  margin-bottom: 30px;
  text-align: center;
}

.form {
  width: 100%;
  max-width: 500px;
}

.input {
  width: 100%;
  padding: 12px;
  font-size: 1.5rem; /* Larger font size for input */
}

.button {
  width: 100%;
  padding: 8px;
  height: 43px;
  margin-top: 10px;
  font-size: 1.8rem; /* Larger font size for button */
}

.table-container {
  margin-top: 50px;
  width: 100%;
  padding: 10px 60px 1px 60px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.th,
.td {
  padding: 20px; /* Increased table padding */
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 1.6rem; /* Larger table text */
}

.subtitle {
  text-align: center;
  font-size: 3rem; /* Larger subtitle */
  margin-bottom: 30px;
}

.warranty-info {
  margin-top: 50px;
  display: flex;
  flex-direction: column; /* Vertical layout for all screen sizes */
  align-items: stretch; /* Cards stretch to full width */
  gap: 30px; /* Space between cards */
}

.card {
  background: #f9f9f9;
  padding: 30px;
  flex-grow: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease; /* Smooth transition for dynamic height */
}

.card-title {
  font-size: 1.8rem; /* Larger card title */
  margin-bottom: 20px;
}

p {
  font-size: 1.5rem; /* Larger paragraph text */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .main {
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust offset, blur, and color as needed */
  }

  .logo {
    width: 150px;
  }

  .title {
    font-size: 3rem; /* Adjusted font size */
  }

  .form {
    max-width: 100%;
  }

  .input {
    padding: 12px;
    font-size: 1.8rem;
  }

  .button {
    padding: 8px;
    height: 43px;
    font-size: 1.5rem; /* Adjusted button font size */
  }

  .table-container {
    max-width: 100%;
    padding: 0px;
  }

  .table,
  .th,
  .td {
    font-size: 1.5rem; /* Larger table font size */
  }

  .warranty-info {
    gap: 25px; /* Slightly reduce gap between cards on smaller screens */
  }

  .card {
    padding: 25px;
  }

  .card-title {
    font-size: 2rem;
  }

  p {
    font-size: 1.5rem; /* Larger paragraph text on small screens */
  }
}

@media (max-width: 480px) {
  .logo {
    width: 120px;
  }

  .title {
    font-size: 2rem;
  }

  .input {
    padding: 10px;
    font-size: 1.3rem;
  }

  .button {
    padding: 8px;
    height: 43px;
    font-size: 1.5rem;
  }

  .th,
  .td {
    font-size: 1.3rem;
    padding: 15px;
  }

  .card {
    padding: 22px;
  }

  .card-title {
    font-size: 2.2rem;
  }

  p {
    font-size: 1.4rem;
  }
}
