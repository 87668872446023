.search-filter {
  &__wrapper {
    padding: 2rem 2rem 2rem 2rem;
  }
  &__search {
    width: 100% !important;
  }
  &__select {
    width: 100% !important;
  }
  &__reset {
    height: 39px !important;
    font-size: larger;
    background-color: #286fd1;
  }

  &__reset:hover {
    background-color: #286fd1 !important;
  }

  &__markReceived {
    height: 39px !important;
    font-size: larger;
    background-color: #286fd1;
  }

  &__markReceived {
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }

    &:disabled:hover {
      background-color: #cccccc !important;
    }

    &:not(:disabled):hover {
      background-color: #286fd1 !important;
    }
  }
}

// Handle hover state to hide select
.search-filter__search:hover ~ .search-filter__select {
  display: none;
}
.filer-content {
  display: flex;
  gap: 20px;
  width: 40rem;
}
