.modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  height: 100%;
}
.search-filter {
  &__markReceived {
    height: 32 !important;
    // font-size: larger;
    background-color: #286fd1;
  }

  &__markReceived {
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }

    &:disabled:hover {
      background-color: #cccccc !important;
    }

    &:not(:disabled):hover {
      background-color: #286fd1 !important;
    }
  }
}
