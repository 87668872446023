// .role-table {
// 	&__action {
// 		display: flex;
// 		gap: 20px;
// 	}
// }

.default-warranty {
  display: flex;
  align-items: center;
  margin: 16px 0;

  .ant-input {
    width: 100px; // Adjust width as needed
  }

  .ant-btn {
    margin-left: 8px;
  }
}
