.customInput {
  margin-bottom: 10px;
}

/* CSS for the Drawer */
.extend-warranty-drawer .formButtons {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  margin-top: 16px; /* Space above buttons */
}

.extend-warranty-drawer .customBtn {
  margin-left: 16px;
}

.customInput .ant-form-item-label > label {
  font-size: 14px; /* Increase label font size */
}

.extend-warranty-drawer .formButtons {
  display: flex;
  justify-content: flex-start; /* Align buttons to the left */
  margin-top: 24px; /* Increase space above buttons */
}

.custom-submit-btn {
  width: 120px; /* Increase width of the Save button */
  font-size: 16px; /* Increase font size */
}

.customBtn {
  width: 120px; /* Increase width of the Save button */
  font-size: 16px; /* Increase font size */
}

.custom-input-disabled {
  font-size: 16px; /* Increase font size of disabled inputs */
}

.ant-drawer-title {
  font-size: 24px !important; /* Increase font size of drawer title */
}
