.inventory-table {
  &__wrapper {
    background-color: white;
    padding: 0 2rem 2rem 2rem;
    border-radius: 10px;
  }

  :global {
    .ant-table {
      .ant-table-thead > tr > th {
        background-color: white;
        font-weight: 600;
        padding: 16px;
      }

      .ant-table-tbody > tr > td {
        padding: 16px;
      }
    }

    .table-global {
      margin: 0 2rem;
    }

    // Additional inventory-specific styles
    .ant-table-row-selected > td {
      background-color: #f0f7ff !important;
    }

    .ant-table-row:hover > td {
      background-color: #f5f5f5;
    }

    .ant-tag {
      min-width: 80px;
      text-align: center;
    }
  }
}
