.side-drawer-form {
  &__inputs {
    margin-bottom: 2.9rem !important;
    padding-left: 3.636rem !important;
    padding-right: 3.636rem !important;
  }

  &__buttons {
    // margin-top: 35rem;
    padding-left: 3.636rem !important;
    padding-right: 3.636rem !important;
    display: flex;
    gap: 2rem;
  }

  &__save {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    margin: 1.3rem 1.3rem 1.3rem 0;
    font-size: 1.6rem !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    cursor: pointer;
    width: 13rem;
    // min-height: 5.2rem;
    &:hover {
      color: #fff !important;
      background: #286fd1 !important;
      border: 1px solid #286fd1;
    }
  }
  &__save-and-add {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #286fd1;
    font-size: 1.6rem;
    color: #fff;
    margin: 1.3rem 1.3rem 1.3rem 0;
    font-weight: 600;
    border-radius: 6px !important;
    border: 1px solid #286fd1;
    padding: 1.3rem 2.8rem;
    cursor: pointer;
    width: 16rem;
    max-height: 39.8px;
    &:hover {
      background: #286fd1 !important;
    }
  }
  &__cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #000;
    margin: 1.3rem 1.3rem 1.3rem 0;
    box-shadow: none;
    font-size: 1.6rem !important;
    color: #000;
    font-weight: 600 !important;
    border-radius: 6px !important;
    cursor: pointer;
    width: 13rem;
    // min-height: 5.2rem;
    &:hover {
      background: #fff !important;
      color: #000 !important;
    }
  }
}

.ant-form-item-explain-error {
  background-color: black !important;
}
