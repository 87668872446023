.shipment-table {
  &__wrapper {
    background-color: white;
    border-radius: 10px;
    overflow: auto;
  }
  :globall {
    .ant-table {
      .ant-table-thead > tr > th {
        background-color: white;
        font-weight: 600;
        padding: 16px;
      }

      .ant-table-tbody > tr > td {
        padding: 16px;
      }
    }
  }
}
