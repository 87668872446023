.statutory-component-tabs {
	//height: 500px;
	.ant-tabs-tab-active {
	//	 background-color: #286fd1 !important;
		color: white !important;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.ant-tabs-tab-btn {
		border: 1px solid #d9d9d9 !important; /* Add a black border */
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		border-bottom: none !important;
		color: gray;
		font-weight: 400 !important;
		padding: 5px 20px !important;
	}
	.ant-tabs-nav-list {
		gap: 10px !important;
	}
	.ant-tabs-tab {
		margin: auto !important;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: white !important;
		background-color: #286fd1 !important;
		//padding: 5px 20px;
		font-size: 18px !important;
	}
	.ant-tabs-ink-bar {
		background-color: none !important;
	}
	.ant-tabs-nav::before {
		border-color: #286fd1 !important;
		border-width: 2px;
	}
	.ant-tabs-nav {
		margin-bottom: 15px !important;
	}
	.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
		font-size: 18px !important;
		padding: 5px 20px !important;
		margin-right: 5px !important;

	}
}

// .ant-tooltip {
// 	color: #fff;
// 	background-color: #fff;
// 	.ant-tooltip-arrow {
// 		color: #fff;
// 	}
// }
